
















































































































































































































































import Vue from "vue";
import { TabLayout } from "@/layouts";
import { Dropdown, SvgIcon, CustomInput } from "@/components";

export default Vue.extend({
  name: "account-admin",

  components: {
    TabLayout,
    Dropdown,
    SvgIcon,
    CustomInput,
  },

  data() {
    return {
      isMarchent: "yes",
    };
  },

  computed: {
    countryOptions(): Array<string> {
      return ["Philippines", "Thailand"];
    },
    regionOptions(): Array<string> {
      return ["NCR - FOURTH DISTRICT", "NCR - FOURTH DISTRICT"];
    },
    provinceOptions(): Array<string> {
      return ["Metro Manila", "Metro Manila"];
    },
    municipaltyOptions(): Array<string> {
      return ["TAGUIG", "TAGUIG"];
    },
    codeOptions(): Array<string> {
      return ["000 0000 000", "000 0000 00"];
    },
    businessOptions(): Array<string> {
      return ["Wholesale", "Retail"];
    },
    statusValue(): string {
      return "PENDING APPLICATION";
    },
  },
});
